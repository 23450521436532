'use client';

import { Button } from '@chakra-ui/react';
import { useDesignTokens } from '../providers/design_tokens_provider';

interface props {
  onSignIn: () => void;
  loading: boolean;
  isDisabled?: boolean;
}

const SignIn = ({ onSignIn, loading, isDisabled }: props) => {
  const tokens = useDesignTokens();
  return (
    <Button
      w='100%'
      colorScheme={tokens.button.secondary.scheme}
      onClick={() => onSignIn()}
      isLoading={loading}
      isDisabled={isDisabled}
    >
      Entrar
    </Button>
  );
};

export default SignIn;
