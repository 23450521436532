'use client';

import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import AntecipameImg from '@public/antecipame.png';
import DoctorImg from '@public/doctor.png';
import EspacoSaudeImg from '@public/espacosaude.png';
import Image from 'next/image';
import useLoading from '../../hooks/useLoading';
import { useTenantConfig } from '../providers/tenant_provider';
import Continue from './continue';
import SignIn from './signin';
import SignOut from './signout';

interface props {
  isAuthenticated: boolean;
  onSignIn: () => void;
  onSignOut: () => void;
  onContinue: () => void;
  loading?: boolean;
}

export default function Login({
  isAuthenticated,
  onSignIn,
  onSignOut,
  onContinue,
  loading,
}: props) {
  const {
    loading: loadingStates,
    setLoadingFalse,
    setLoadingTrue,
    anyLoading,
  } = useLoading({ continue: false, signIn: false, signOut: false });

  const config = useTenantConfig();
  const Img = (() => {
    switch (config?.landpage) {
      case 'antecipame':
        return AntecipameImg;
      case 'agencia':
        return AntecipameImg;
      case 'espacosaude':
        return EspacoSaudeImg;
      default:
        return DoctorImg;
    }
  })();

  const onClickContinue = () => {
    setLoadingTrue('continue');
    onContinue();
  };

  const onClickSignIn = () => {
    setLoadingTrue('signIn');
    onSignIn();
  };
  const onClickSignOut = () => {
    setLoadingTrue('signOut');
    onSignOut();
  };

  return (
    <>
      <Flex justifyContent='center' alignItems='center' w='100%' h='100%'>
        <Flex
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          flexGrow={1}
        >
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='flex-start'
            w='50%'
          >
            <Box marginBottom={2}>
              <img src={config.logo} alt='logo' width={400} height={400} />
            </Box>

            <Text fontSize='3xl' marginBottom={7}>
              Backoffice do Cadastro de Profissionais
            </Text>

            <Flex w='100%' direction={'column'} alignItems={'center'} gap={4}>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {isAuthenticated ? (
                    <>
                      <Continue
                        loading={loadingStates.continue}
                        onContinue={onClickContinue}
                        isDisabled={anyLoading()}
                      />
                      <SignOut
                        loading={loadingStates.signOut}
                        onSignOut={onClickSignOut}
                        isDisabled={anyLoading()}
                      />
                    </>
                  ) : (
                    <SignIn
                      loading={loadingStates.signIn}
                      onSignIn={onClickSignIn}
                    />
                  )}
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Box maxH='100vh'>
          <Image src={Img} alt='Imagem doutor' />
        </Box>
      </Flex>
    </>
  );
}
