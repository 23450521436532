import { useState } from 'react';

export default function useLoading<T extends Record<string, boolean>>(
  initialState: T,
) {
  const [loading, setLoading] = useState<T>(initialState);

  function setLoadingTrue(key: keyof T) {
    setLoading({ ...loading, [key]: true });
  }

  function setLoadingFalse(key: keyof T) {
    setLoading({ ...loading, [key]: false });
  }

  function toggleLoading(key: keyof T) {
    setLoading({ ...loading, [key]: !loading[key] });
  }

  function anyLoading(keys?: Array<keyof T>) {
    if (keys) {
      return keys.some((k) => loading[k]);
    }

    return Object.values(loading).some((e) => e);
  }

  return {
    loading,
    setLoadingTrue,
    setLoadingFalse,
    toggleLoading,
    anyLoading,
  };
}
