'use client';

import { Button } from '@chakra-ui/react';

interface props {
  onSignOut: () => void;
  loading: boolean;
  isDisabled?: boolean;
}

const SignOut = ({ onSignOut, loading, isDisabled }: props) => {
  return (
    <Button
      w='100%'
      colorScheme='red'
      variant={'outline'}
      onClick={() => onSignOut()}
      isLoading={loading}
      isDisabled={isDisabled}
    >
      Sair
    </Button>
  );
};

export default SignOut;
